/* Every formating will be done in en-US locale */
type DateChangeOptions = 'day' | 'month' | 'year';
const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const SHORT_HAND_WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const ULTRA_SHORT_HAND_WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const SHORT_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
declare global {
    interface Date {
        format(formate: string): string;
        year(): number;
        month(): number;
        date(): number;
        add(value: number, literal: DateChangeOptions): Date;
    }
}
Date.prototype.format = function(formate: string) {
    const date: Date = this;
    return dateFormating(formate, date);
};
Date.prototype.year = function() {
    const date: Date = this;
    return date.getFullYear();
};
Date.prototype.month = function() {
    const date: Date = this;
    return date.getMonth();
};
Date.prototype.date = function() {
    const date: Date = this;
    return date.getDate();
};
Date.prototype.add = function(newValue: number, literal: DateChangeOptions) {
    const date: Date = this;
    switch (literal) {
        case 'day':
            date.setDate(newValue + date.date());
            break;
        case 'month':
            date.setMonth(newValue + date.month());
            break;
        case 'year':
            date.setFullYear(newValue + date.year());
            break;
    }
    return date;
};
function dateFormating(format: string, date: Date) {
    /*
        defination of coversion
        MM => Month(e.g 1..12)
        MMM => Month(e.g Jan, Feb ...Dec)
        MMMM => Month (e.g January....December)

        D => Day of month(e.g 1...31)
        DD => Day of month in two digit(e.g 01...31)

        YY => short hand year (e.g 21 for 2021)
        YYYY => 4 digit year (e.g 2021)

        dd. => ultra short string literal for day of week (e.g Su. Mo. ....Sa.)
        ddd => short hand string literal for day of week (e.g Sun...Sat)
        dddd => string literal for day of week (e.g Sunday...Saturday)

        LL => Month day, year(eg March 4, 2019)
        TODO:// Time stamp conversion
        full Regex=> /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g
    */
    if (!format) {
        throw new Error('Invalid format');
    }
    /* check if any padding is required */
    const padStart = (str: string | number, length: number, pad: string) => {
        const s = str.toString();
        if (!s || s.length >= length) {
            return str;
        }
        return `${Array(length + 1 - s.length).join(pad)}${str}`;
    };
    const formattingObj = {
        D: date.date().toString(),
        DD: date.date().toString().length === 1 ? `0${date.date()}` : date.date().toString(),
        dd: ULTRA_SHORT_HAND_WEEK_DAYS[date.getDay()],
        ddd: SHORT_HAND_WEEK_DAYS[date.getDay()],
        dddd: WEEK_DAYS[date.getDay()],
        M: (date.month() + 1).toString(),
        MM: (date.month() + 1).toString().length === 1 ? `0${date.month() + 1}` : (date.month() + 1).toString(),
        MMM: SHORT_MONTHS[date.month()],
        MMMM: MONTHS[date.month()],
        YY: date
            .year()
            .toString()
            .substring(2),
        YYYY: date.year().toString(),
        LL: `${MONTHS[date.month()]} ${date.date()}, ${date.year()}`,
        HH: padStart(date.getHours(), 2, '0'),
        mm: padStart(date.getMinutes(), 2, '0'), // TODO: add more time stamp related conversion
    };

    return format.replace(
        /\[([^\]]+)]|Y{2,4}|M{1,4}|D{1,2}|d{2,4}|L{2}|H{1,2}|m{1,2}/g,
        (match) => formattingObj[match] || '',
    );
}
export {};
